import React, { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { useEntity } from "@backstage/plugin-catalog-react";
import { EaPackSystemEntityV1beta1 } from "@internal/plugin-ea-pack-common";
import { PandoraGrey, PandoraGreen } from "@internal/plugin-pandora-react";
import { LinkButton } from "@backstage/core-components";

export const PlatformEnablementCard = (props: { isOwner: boolean, isPlatformEnabled: boolean }) => {
  const { isOwner, isPlatformEnabled } = props;
  const entity = useEntity().entity as EaPackSystemEntityV1beta1;

  const enableNowLink = `/create/templates/default/enable-platform-engineering?formData={"product"%3A"${encodeURIComponent(entity.metadata.name)}"}`;

  return (
    <Card>
      <CardHeader title="Platform Enablement" />
      <CardContent>
        <Grid container direction="row">
          <Grid item>
            <VerifiedUserIcon style={{ fill: isPlatformEnabled ? PandoraGreen : PandoraGrey, zoom: 2 }} />
          </Grid>
          <Grid item alignContent="stretch">
            {isPlatformEnabled && <Typography variant="body1">This product is enabled for Platform Engineering 🚀! Start consuming any of the enablers below</Typography>}
            {!isPlatformEnabled && <>
              <Typography variant="body1">This product is not enabled for Platform Engineering 🥺.</Typography>
              {isOwner &&
                <LinkButton to={enableNowLink} variant="outlined" style={{ backgroundColor: PandoraGreen }}>Enable now 🚀</LinkButton>}
            </>}
          </Grid>
        </Grid>
      </CardContent>
    </Card >
  );
}